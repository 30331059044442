exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bireysel-egitimler-js": () => import("./../../../src/pages/bireysel-egitimler.js" /* webpackChunkName: "component---src-pages-bireysel-egitimler-js" */),
  "component---src-pages-corpus-blog-item-js": () => import("./../../../src/pages/corpus-blog-item.js" /* webpackChunkName: "component---src-pages-corpus-blog-item-js" */),
  "component---src-pages-corpus-blog-js": () => import("./../../../src/pages/corpus-blog.js" /* webpackChunkName: "component---src-pages-corpus-blog-js" */),
  "component---src-pages-egitimler-js": () => import("./../../../src/pages/egitimler.js" /* webpackChunkName: "component---src-pages-egitimler-js" */),
  "component---src-pages-gizlilik-ve-guvenlik-js": () => import("./../../../src/pages/gizlilik-ve-guvenlik.js" /* webpackChunkName: "component---src-pages-gizlilik-ve-guvenlik-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaynaklar-js": () => import("./../../../src/pages/kaynaklar.js" /* webpackChunkName: "component---src-pages-kaynaklar-js" */),
  "component---src-pages-kitaplar-js": () => import("./../../../src/pages/kitaplar.js" /* webpackChunkName: "component---src-pages-kitaplar-js" */),
  "component---src-pages-neden-corpus-js": () => import("./../../../src/pages/neden-corpus.js" /* webpackChunkName: "component---src-pages-neden-corpus-js" */),
  "component---src-pages-okullar-icin-egitimler-js": () => import("./../../../src/pages/okullar-icin-egitimler.js" /* webpackChunkName: "component---src-pages-okullar-icin-egitimler-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-detay-js": () => import("./../../../src/templates/blog-detay.js" /* webpackChunkName: "component---src-templates-blog-detay-js" */)
}

